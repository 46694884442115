<template>
  <div class="grid">
    <div class="col-4">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button :label="buttonLabel" icon="pi pi-upload" class="p-button-success" @click="importar()" />
          </template>
        </Toolbar>
        <hr />
        <div style="width: 100%; overflow: auto;">
          <h6>
            Base Geral - Acesse aqui o exemplo de arquivo de upload:
            <a href="/app/front/mock_data.csv">Clique Aqui</a>
          </h6>
          <hr />
          <!-- <a style="cursor: pointer;" @click="abrir('base1')">Veja os Dados Importados - Clique Aqui</a> -->
        </div>

        <!-- Dialog de CSV Upload -->
        <Dialog v-model:visible="modalcsv" :style="{ width: '600px' }" :modal="true"
          header="Importar CSV - Separado por ;">
          <div class="flex align-items-center justify-content-center">
            <div class="field">
              <FileUpload chooseLabel="Selecionar arquivo csv" ref="arquivo" mode="basic" name="arquivo"
                @change="onChangeFileUpload()" />
            </div>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalcsv = false" />
            <Button label="Importar" icon="pi pi-check" class="p-button-success p-button-sm" id="base1"
              @click="enviarCsv()" />
          </template>
        </Dialog>

        <!-- Dialog de Zip Upload -->
        <Dialog v-model:visible="modalzip" :style="{ width: '600px' }" :modal="true" header="Importar Zip">
          <div class="flex align-items-center justify-content-center">
            <div class="field">
              <FileUpload chooseLabel="Selecionar arquivo zip" ref="arquivoZip" mode="basic" name="arquivoZip"
                @change="onChangeFileUploadZip" />
            </div>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalzip = false" />
            <Button label="Importar" icon="pi pi-check" class="p-button-success p-button-sm" id="baseZip"
              @click="enviarZip()" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalcsv: false,
      modalzip: false,
      contrato: false,
      fatura: false,
      arquivo: "",
      arquivoZip: "",
    };
  },
  computed: {
    buttonLabel() {
      // Se for fatura (tipo_faturamento == 22845207), então exibir "Importar Zip"
      return this.fatura ? 'Importar Zip' : 'Importar CSV';
    },
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log('user.tipo_faturamento', user.tipo_faturamento);
    //user.tipo_faturamento = "22845207"
    if (user && user.tipo_faturamento == "22845207") {
      this.contrato = false;
      this.fatura = true;
    } else {
      this.contrato = true;
      this.fatura = false;
    }
  },
  methods: {
    importar() {
      if (this.fatura) {
        this.importarZip();
      } else {
        this.importarCsv();
      }
    },
    importarCsv() {
      this.modalcsv = true;
    },
    importarZip() {
      this.modalzip = true;
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },
    onChangeFileUploadZip() {
      this.arquivoZip = this.$refs.arquivoZip.files[0];
    },
    enviarCsv() {
      let bt = document.getElementById('base1');
      bt.disabled = true;
      bt.innerHTML = "Aguarde...";
      let formData = new FormData();
      formData.append("arquivo", this.arquivo);

      // Rota utilizada quando não é fatura (Ex: Operadora)
      this.axios
        .post("/fornecedores/upload-operadora", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Planilha Importada com sucesso",
            life: 3000,
          });
          this.modalcsv = false;
          bt.disabled = false;
          bt.innerHTML = "Enviar";
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
          bt.disabled = false;
          bt.innerHTML = "Enviar";
        });
    },
    enviarZip() {
      let bt = document.getElementById('baseZip');
      bt.disabled = true;
      bt.innerHTML = "Aguarde...";
      let formData = new FormData();
      formData.append("arquivoZip", this.arquivoZip);

      console.log('ArquivoZip:', this.arquivoZip);

      this.axios.post("/fornecedores/upload-tower", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Arquivo Zip Importado com sucesso",
            life: 3000
          });
          this.modalzip = false;
          bt.disabled = false;
          bt.innerHTML = "Enviar";
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000
          });
          bt.disabled = false;
          bt.innerHTML = "Enviar";
        });
    },
    abrir(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.tabela {
  margin: 20px 0;
}

.tabela th {
  white-space: nowrap;
  background: #EEE;
  padding: 5px;
}
</style>
